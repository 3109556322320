import logo from './faviconnn.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h3>Welcome to Discovery-Connect</h3>
        <p className="coming-soon">
          WE ARE COMING SOON!
        </p>
        <div className="App-description">
          <h6 style={{ marginLeft: '415px', marginRight: '400px', textAlign: 'center', color: '#D32F2F' }}>
            Transforming Disease Research with Discovery Connect
          </h6>
          <p style={{
            fontSize: '19px',
            marginLeft: '350px',
            marginRight: '350px',
            textAlign: 'justify',
            marginTop: '-20px' // Adjust upward
          }}>
            Facilitating scientific research by collecting biological samples from patients diagnosed with specific diseases. These samples will be used by researchers and laboratories across the country, driving diverse scientific studies and advancements.
          </p>
          <p style={{
            fontSize: '19px',
            marginLeft: '350px',
            marginRight: '350px',
            textAlign: 'justify'
          }}>
            Our primary objective is to gather positive disease state human samples, such as blood, serum, plasma, and tissue, from various hospital laboratories. These samples will be anonymized and securely stored. Researchers from different institutions will have access to these samples for approved research projects, ensuring a more efficient and collaborative approach to disease research and IVD industry.
          </p>
          <p style={{
            fontSize: '19px',
            marginLeft: '350px',
            marginRight: '350px',
            textAlign: 'justify'
          }}>
            Stay tuned for the launch of our online database, revolutionizing the way disease research is conducted.
          </p>


        </div>
      </header>
    </div>
  );
}

export default App;
